<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h1>{{ $t('Album') }}: {{ release.ProductDetails.Title }} - {{ release.ProductDetails.Attribution }}</h1>

        <p v-if="maintenance">{{ $t('This option is currently unavailable due to maintenance.') }}</p>
        <b-alert :show="errorMessage !== null" variant="danger">
          {{ errorMessage }}
        </b-alert>

        <router-link v-if="maintenance" 
          :to="{ name: 'release-detail' }" tag="b-button" class="ml-2">{{ $t('Back') }}</router-link>

        <page-snippet name="portal-update" class="" v-if="false" />

        <b-card :header="$t('Portals')" class="mt-4" v-if="(!errorMessage && !maintenance) || $root.$data.me.EMail == 'mail@erikbas.nl'">
          <b-spinner label="Spinning" v-if="releaseOutlets == null"></b-spinner>
          <b-table-simple bordered v-if="releaseOutlets != null">
            <tbody>
              <template v-for="(outlet, index) in outlets">
                <tr :key="'a' + index">
                  <td class="outlet-image">
                    <img v-if="outlet.ImageUrl" v-bind:class="{ 'portal-logo': true, disabled: !selectedOptions.includes(outlet.JsonValue) }" :src="outlet.ImageUrl" :alt="outlet.Value" />
                  </td>
                  <td>
                    <h3>{{ outlet.Value }}</h3>
                    <p>{{ outlet.Description }}</p>
                  </td>
                  <td>
                    <b-button-group v-if="!blockedOptions.includes(outlet.JsonValue)">
                      <b-button class="text-nowrap" :variant="selectedOptions.includes(outlet.JsonValue) ? 'success' : 'secondary'" @click="toggle(outlet.JsonValue)">{{ $t('Available') }}</b-button>
                      <b-button class="text-nowrap" :variant="!selectedOptions.includes(outlet.JsonValue) ? 'danger' : 'secondary'" @click="toggle(outlet.JsonValue)">{{ $t('Not Available') }}</b-button>
                    </b-button-group>

                    <page-snippet name="outlet_blocked" v-if="blockedOptions.includes(outlet.JsonValue)" />
                  </td>
                </tr>
                <tr :key="'beatport' + index" v-if="outlet.JsonValue == 'Beatport' && selectedOptions.includes('Beatport')">
                  <td></td>
                  <td colspan="2">
                    <page-snippet name="beatport-label" class="" />
                  </td>
                </tr>
              </template>
            </tbody>
          </b-table-simple>

          <b-table-simple bordered v-if="changes">
            <thead>
              <tr>
                <th width="50%">{{ $t('Portals added') }}</th>
                <th width="50%">{{ $t('Portals deleted') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li v-for="outlet in addedOptions" :key="outlet.JsonValue">
                      {{ outlet.Value }}
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li v-for="outlet in removedOptions" :key="outlet.JsonValue">
                      {{ outlet.Value }}
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </b-table-simple>

          <b-button variant="primary" :disabled="!changes" v-if="releaseOutlets != null" @click="$emit('save-outlet-settings', changeJson)">
            <template v-if="!changes">{{ $t('No changes have been made') }}</template>
            <template v-if="changes">{{ $t('Confirm changes') }}</template>
          </b-button>

          <template v-if="releaseOutlets != null && [2,90].includes(release.ProductDetails.DeliveryStatus.StatusID)">
            <b-button @click.prevent v-b-modal.takedown-modal class="ml-2">{{ $t('Full take down') }}</b-button>

            <b-modal id="takedown-modal" :title="this.$t('Full take down')" :ok-title="this.$t('Confirm')" :cancel-title="this.$t('Cancel')" @ok="$emit('takedown')">
              <div v-html="$root.$data.pageSnippets['full-take-down']" />
            </b-modal>
          </template>

          <router-link 
            v-if="releaseOutlets != null"
            :to="{ name: 'release-detail' }" tag="b-button" class="ml-2">{{ $t('Cancel') }}</router-link>
        </b-card>

        <pre v-if="['ronald@trcmusic.com', 'erik.bas@the-source.eu'].includes($root.$data.me.Email) || $root.$data.me.IsSuperUser">
          {{ changeJson }}
        </pre>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'ReleaseDetailOutlets',
    components: {
      'page-snippet': () => import('@/components/PageSnippet'),
    },
    data () {
      return {
        defs: this.$root.$data.fieldDefs.Album,
        pageTitle: this.$t('Album outlets'),
        releaseOutlets: null,
        maintenance: false,
        errorMessage: null,
        virtualOutlets: {
          FacebookAll: ['Facebook_AudioLibrary', 'Facebook_FingerPrinting'],
        }
      }
    },
    props: {
      release: Object,
    },
    methods: {
      toggle (value) {
        // Exception for Virtual Outlets
        const virtualOutlet = this.virtualOutlets[value]
        if (virtualOutlet) {
          let toToggle = this.releaseOutlets.filter(outlet => virtualOutlet.includes(outlet.name));

          if (toToggle.length == 0) {
            for (let name of virtualOutlet) {
              this.releaseOutlets.push({
                name: name,
                deliverTo: null,
                deliverToNew: true 
              })
            }
          } else if (toToggle.length == 2) {
            for (let outlet of toToggle) {
              outlet.deliverToNew = !outlet.deliverToNew 
            }
          } else {
            this.$notify({
              type: 'danger',
              text: this.$t('There is an issue with your outlet setting. Please contact support.')
            });
          }
          return
        }

        let toToggle = this.releaseOutlets.filter(outlet => {
          return outlet.name == value || 
                 outlet.name == 'Legacy-' + value ||
                 outlet.name == value.replace('iTunesBoep', 'iTunes') ||
                 outlet.name == value.replace('iTunesBoep', 'Legacy-iTunes')
        });

        if (toToggle.length == 0) {
          let outlet = this.$root.$data.fieldDefs.Album['Attributes.Deliver to'].Options.find(outlet => { return outlet.JsonValue == value });
          this.releaseOutlets.push({
            name: value,
            logoUrl: outlet.ImageUrl,
            deliverTo: null,
            deliverToNew: true
          })
        } else {
          for (let outlet of toToggle)
            outlet.deliverToNew = !outlet.deliverToNew 
        } 
      },
      virtualOutletFix (names, returnOptions = false) {
        for (let vo_name in this.virtualOutlets) {
          const vo = this.virtualOutlets[vo_name]
          let found = names.filter(name => vo.includes(name))

          if (found.length == 0) continue
          
          if (found.length !== vo.length) {
            this.errorMessage = this.$t('Invalid setting for outlet. Please contact support')
          }

          names = names.filter(name => !vo.includes(name)) 
          names.push(vo_name)
        }

        if (!returnOptions) return names

        return this.outlets.filter(outlet => names.includes(outlet.JsonValue))
      },
    },
    computed: {
      blocked: {
        get () {
          if (this.releaseOutlets == null) return []

          let out = []
          for (let outlet of this.releaseOutlets) {
            let name = outlet.name
            if (outlet.blocked == true) out.push(name)
          }
          return out
        },
      },
      selected: {
        get () {
          if (this.releaseOutlets == null) return []

          let out = []
          for (let outlet of this.releaseOutlets) {
            let name = outlet.name
            name = name.replace('Legacy-iTunes', 'iTunesBoep')
            name = name.replace('Legacy-', '')
            if (outlet.deliverToNew == true) out.push(name)
          }
          return out
        },
      },
      removed () {
        if (!this.releaseOutlets) return []
        let out = []
        for (let outlet of this.releaseOutlets) {
          if (outlet.deliverTo == true && outlet.deliverToNew == false) out.push(outlet.name)
        }
        return out
      },
      added () {
        if (!this.releaseOutlets) return []
        let out = []
        for (let outlet of this.releaseOutlets) {
          if (outlet.deliverTo != outlet.deliverToNew && outlet.deliverToNew == true) out.push(outlet.name)
        }
        return out
      },
      selectedOptions () { return this.virtualOutletFix(this.selected) },
      blockedOptions () { return this.virtualOutletFix(this.blocked) },
      removedOptions () { return this.virtualOutletFix(this.removed, true) },
      addedOptions () { return this.virtualOutletFix(this.added, true) },
      changes () {
        return this.removed.length > 0 || this.added.length > 0
      },
      changeJson () {
        if (!this.releaseOutlets) return []

        let changed = this.added.concat(this.removed)
        let out = {}
        console.log('changed', changed)

        for (let name of changed) {
          name = name.replace('Legacy-iTunes', 'iTunesBoep')
          name = name.replace('Legacy-', '')
          out[name] = this.releaseOutlets.find(outlet => outlet.name == name).deliverToNew
        }

        return out
      },
      outlets () {
        return this.$root.$data.fieldDefs.Album['Attributes.Deliver to'].Options.filter(o => {
          if (['YouTube_ContentID', 'Inprodicon', 'Mixcloud'].includes(o.JsonValue)) return false
          let genre = this.release.MainGenre
          if (genre != 'Classical' && ['Naxos', 'Primephonic'].includes(o.JsonValue)) return false
          if (genre != 'Dance' && ['Traxsource', 'Juno', 'Beatport'].includes(o.JsonValue)) return false
          return true
        })
      },
    },
    mounted () {
      this.$root.$data.crumbs = [
        {to: { name: 'release-search' }, text: this.$t('Albums')},
        {text: this.release.ProductDetails.Attribution + ' - ' + this.release.ProductDetails.Title},
        {text: 'Outlets'},
      ]

      this.$http
        .get('albums/'+this.release.AlbumID+'/outlet-portals')
        .then(response => {
            response.data.forEach(o => {
              o.deliverToNew = o.deliverTo
            })
            this.releaseOutlets = response.data    
            //this.releaseOutlets = []
        }).catch(function () {
            this.releaseOutlets = null
        })
    }
  }
</script>

<style lang="scss" scoped>
  td.outlet-image {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .portal-logo {
    max-width: 100px;
    max-height: 75px;
  }

  img.disabled {
    opacity: 0.5;
    filter: gray; /* IE */
    -webkit-filter: grayscale(1); /* Old WebKit */
    -webkit-filter: grayscale(100%); /* New WebKit */
    filter: grayscale(100%); /* Current draft standard */
  }
</style>